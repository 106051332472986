import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import vi from "date-fns/locale/vi"; // Import locale tiếng Việt
import "daterangepicker";
import moment from "moment";
import axios from "axios";

function ChiTiet() {
  registerLocale("vi", vi); // Đăng ký ngôn ngữ tiếng Việt
  setDefaultLocale("vi"); // Đặt ngôn ngữ mặc định là tiếng Việt
  const [noisinhTinh1, setNoiSinhTinh1] = useState([]);
  const [noicapcccdTinh, setNoiCapCCCDTinh] = useState([]);
  const [provinces1, setProvinces1] = useState([]);
  const [districts1, setDistricts1] = useState([]);
  const [wards1, setWards1] = useState([]);
  const [provinces2, setProvinces2] = useState([]);
  const [districts2, setDistricts2] = useState([]);
  const [wards2, setWards2] = useState([]);

  // Tỉnh Huyện Xã //

  let [selectedOption, setSelectedOption] = useState("");
  let [rows, setRows] = useState([{ thoigian: "", congviec: "" }]);
  const [stks, setStk] = useState("");
  let [selectedTamTruTinh, setSelectedTamTruTinh] = useState("");
  const [selectedTamTruHuyen, setSelectedTamTruHuyen] = useState("");
  const [selectedTamTruXa, setSelectedTamTruXa] = useState("");
  let [selectedThuongTruTinh, setSelectedThuongTruTinh] = useState("");
  const [selectedThuongTruHuyen, setSelectedThuongTruHuyen] = useState("");
  const [selectedThuongTruXa, setSelectedThuongTruXa] = useState("");

  const Tinh = () => {
    axios
      // .get("http://localhost:30002/api/provinces")
      .get("https://servertd.vietlonghung.com.vn/api/provinces")
      .then((response) => {
        setProvinces1(response.data);
        setProvinces2(response.data);
        setNoiSinhTinh1(response.data);
        setNoiCapCCCDTinh(response.data);
      })
      .catch((error) => console.error("Error fetching provinces:", error));
  };

  useEffect(() => {
    Tinh();
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const [cccd, setCccd] = useState("");
  const [dataForm, setDataForm] = useState(null);
  const [error, setError] = useState("");
  const handleSearch = async (e) => {
    // Tinh();
    e.preventDefault();
    try {
      const response = await axios.get(
        `https://servertd.vietlonghung.com.vn/api/search/${cccd}`
        //  `http://localhost:30002/api/search/${cccd}`
      );
      setDataForm(response.data);
      if (response.data.tomtacbt.length > 0) {
        // rows = response.data.tomtacbt;
        setRows(response.data.tomtacbt);
      } else {
        setRows([
          { thoigian: "", congviec: "" },
          { thoigian: "", congviec: "" },
        ]);
      }

      // Tách dữ liệu từ MongoDB khi component được mount
      if (response.data.stk) {
        const [option, account] = response.data.stk.split(" - ");
        setSelectedOption(option);
        setStk(account);
      }

      selectedThuongTruTinh = response.data.tinh;
      selectedTamTruTinh = response.data.tamtruTinh;
      setIsLoading(true);
      handleThuongTruTinh(response.data.tinh);
      handleThuongTruHuyenChange(response.data.huyen);
      handleThuongTruXa(response.data.xa);
      handleTamTruTinh(response.data.tamtruTinh);
      handleTamTruHuyenChange(response.data.tamtruHuyen);
      handleTamTruXa(response.data.tamtruXa);

      setError("");
    } catch (error) {
      setError("Không tìm thấy thông tin đăng ký của Căn cước công dân này.");
      setRows([]);
      setDataForm(null);
    } finally {
      setIsLoading(false); // Kết thúc tìm kiếm
      // console.log(districts2);
    }
  };

  const handleInputSearchChange = (e) => {
    setCccd(e.target.value);
  };

  // Function to fetch districts based on province code
  const Huyen = (tinh_thanh, setDistrictsFunction) => {
    if (tinh_thanh) {
      axios
        .get(
          `https://servertd.vietlonghung.com.vn/api/provinces/${tinh_thanh}/districts`
        )
        .then((response) => {
          setDistrictsFunction(response.data);
        })
        .catch((error) => console.error("Lỗi khi lấy danh sách huyện:", error));
    } else {
      setDistrictsFunction([]);
    }
  };

  // Function to fetch wards based on province code and district code
  const Xa = async (tinh, huyen, setWardsFunction) => {
    if (huyen) {
      await axios
        .get(
          `https://servertd.vietlonghung.com.vn/api/provinces/${tinh}/districts/${huyen}/wards`
        )
        .then((response) => {
          setWardsFunction(response.data);
        })
        .catch((error) => console.error("Lỗi khi lấy danh sách xã:", error));
    } else {
      setWardsFunction([]);
    }
  };

  // Handle change event for province selection in temporary residence address
  const handleTamTruTinh = (e) => {
    let provinceCode;
    if (typeof e === "string") {
      provinceCode = e;
    } else {
      provinceCode = e.target.value;
    }
    setSelectedTamTruTinh(provinceCode);
    setSelectedTamTruHuyen("");
    setSelectedTamTruXa("");

    Huyen(provinceCode, setDistricts1);
  };

  // Handle change event for district selection in temporary residence address
  const handleTamTruHuyenChange = (e) => {
    let districtCode;
    if (typeof e === "string") {
      districtCode = e;
    } else {
      districtCode = e.target.value;
    }
    setSelectedTamTruHuyen(districtCode);
    setSelectedTamTruXa("");

    Xa(selectedTamTruTinh, districtCode, setWards1);
  };

  const handleTamTruXa = (e) => {
    let provinceCode;
    if (typeof e === "string") {
      provinceCode = e;
    } else {
      provinceCode = e.target.value;
    }

    setSelectedTamTruXa(provinceCode);
  };

  // Handle change event for province selection in permanent residence address
  const handleThuongTruTinh = async (e) => {
    let provinceCode;
    if (typeof e === "string") {
      provinceCode = e;
    } else {
      provinceCode = e.target.value;
    }
    setSelectedThuongTruTinh(provinceCode);
    setSelectedThuongTruHuyen("");
    setSelectedThuongTruXa("");

    await Huyen(provinceCode, setDistricts2);
  };

  // Handle change event for district selection in permanent residence address
  const handleThuongTruHuyenChange = async (e) => {
    let districtCode;
    if (typeof e === "string") {
      districtCode = e;
    } else {
      districtCode = e.target.value;
    }
    setSelectedThuongTruHuyen(districtCode);
    setSelectedThuongTruXa("");

    // await Xa("Tỉnh Bắc Ninh", districtCode, setWards2);
    await Xa(selectedThuongTruTinh, districtCode, setWards2);
  };

  const handleThuongTruXa = (e) => {
    let provinceCode;
    if (typeof e === "string") {
      provinceCode = e;
    } else {
      provinceCode = e.target.value;
    }

    setSelectedThuongTruXa(provinceCode);
  };
  // console.log(DasetDataForm);

  const handleAddRow = (name) => {
    setRows([...rows, { thoigian: "", congviec: "" }]);
  };
  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedOption(value);
    if (value !== "Đã có") {
      setSTKnew(""); // Xóa giá trị nhập vào nếu lựa chọn không phải "Đã có"
      setSTKnew(value); // Chỉ lưu lựa chọn vào stknew
    } else {
      // Nếu lựa chọn là "Đã có", giữ nguyên giá trị của stks
      setSTKnew(`${value} - ${stks}`);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // console.log(DasetDataForm);
  };

  const [stknew, setSTKnew] = useState("");

  const handleInputAccoutChange = (e) => {
    const { value } = e.target;
    setStk(value);
    if (selectedOption === "Đã có") {
      setSTKnew(`${selectedOption} - ${value}`);
    } else {
      setSTKnew(value);
    }
  };

  const handleRowChange = (index, event) => {
    const { name, value } = event.target;
    const newRows = [...rows];
    newRows[index][name] = value;
    setRows(newRows);
  };

  // Tính ngày tối thiểu để người dùng đủ 18 tuổi
  const maxDate = moment().subtract(18, "years").toDate();
  // const handleDateChange = (date, fieldName) => {
  //   const formattedDate = moment(date).format("DD/MM/YYYY");
  //   setDataForm({
  //     ...dataForm,
  //     [fieldName]: formattedDate,
  //   });
  // };
  const handleDateChange = (date, fieldName) => {
    if (date && moment(date).isValid()) {
      const formattedDate = moment(date).format("DD/MM/YYYY");
      setDataForm({
        ...dataForm,
        [fieldName]: formattedDate,
      });
    } else {
      // Handle the error or set a default value
      setDataForm({
        ...dataForm,
        [fieldName]: "",
      });
    }
  };

  const [showNote, setShowNote] = useState(false);

  const handleShowNote = () => {
    setShowNote(true);
  };

  const phoneGroups = [
    ["sdtong"],
    ["sdtba"],
    ["sdtcha"],
    ["sdtme"],
    ["sdtchongvo"],
    ["sdtcon1"],
    ["sdtcon3"],
    ["sdtcon2"],
    ["sdtchavo"],
    ["sdtmevo"],
    ["sdta1"],
    ["sdta2"],
    ["sdtc1"],
    ["sdtc2"],
    ["sdte1"],
    ["sdte2"],
    ["sdtacevo"],
    ["sdtkhac"],
  ];

  const isPhoneNumbersFilled = () => {
    let countFilledPhoneGroups = 0;

    const checkPhoneGroup = (fields) => {
      const allFilled = fields.every((field) => dataForm[field]);
      const allEmpty = fields.every((field) => !dataForm[field]);
      return allFilled || allEmpty;
    };

    for (let group of phoneGroups) {
      if (!checkPhoneGroup(group)) {
        return { valid: false, countFilledPhoneGroups };
      }
      if (group.every((field) => dataForm[field])) {
        countFilledPhoneGroups++;
      }
    }

    return { valid: countFilledPhoneGroups >= 2, countFilledPhoneGroups };
  };

  const isOtherTextboxesFilled = () => {
    let countFilledGroups = 0;

    const checkGroup = (fields) => {
      const allFilled = fields.every((field) => dataForm[field]);
      const allEmpty = fields.every((field) => !dataForm[field]);
      return allFilled || allEmpty;
    };

    const groups = [
      ["hotenong", "ngaysinhong", "nghenghiepong", "chooong"],
      ["hotenba", "ngaysinhba", "nghenghiepba", "chooba"],
      ["hotencha", "ngaysinhcha", "nghenghiepcha", "choocha"],
      ["hotenme", "ngaysinhme", "nghenghiepme", "choome"],
      ["hotenchongvo", "ngaysinhchongvo", "nghenghiepchongvo", "choochongvo"],
      ["hotencon1", "ngaysinhcon1", "nghenghiepcon1", "choocon1"],
      ["hotencon2", "ngaysinhcon2", "nghenghiepcon2", "choocon2"],
      ["hotencon3", "ngaysinhcon3", "nghenghiepcon3", "choocon3"],
      ["hotenchavo", "ngaysinhchavo", "nghenghiepchavo", "choochavo"],
      ["hotenmevo", "ngaysinhmevo", "nghenghiepmevo", "choomevo"],
      ["hotena1", "ngaysinha1", "nghenghiepa1", "chooa1"],
      ["hotena2", "ngaysinha2", "nghenghiepa2", "chooa2"],
      ["hotenc1", "ngaysinhc1", "nghenghiepc1", "chooc1"],
      ["hotenc2", "ngaysinhc2", "nghenghiepc2", "chooc2"],
      ["hotene1", "ngaysinhe1", "nghenghiepe1", "chooe1"],
      ["hotene2", "ngaysinhe2", "nghenghiepe2", "chooe2"],
      ["hotenacevo", "ngaysinhacevo", "nghenghiepacevo", "chooacevo"],
      ["hotenkhac", "ngaysinhkhac", "nghenghiepkhac", "chookhac"],
    ];

    for (let group of groups) {
      if (!checkGroup(group)) {
        return { valid: false, countFilledGroups };
      }
      if (group.every((field) => dataForm[field])) {
        countFilledGroups++;
      }
    }

    return { valid: countFilledGroups > 2, countFilledGroups };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { valid: validOtherFields, countFilledGroups } =
      isOtherTextboxesFilled();
    const { valid: validPhoneFields, countFilledPhoneGroups } =
      isPhoneNumbersFilled();

    if (!validOtherFields || !validPhoneFields) {
      alert(
        `Bạn phải nhập đủ thông tin cho ít nhất 3 thông tin người thân và số điện thoại. Hiện tại bạn đã nhập đủ ${countFilledGroups} thông tin người thân và ${countFilledPhoneGroups} nhóm số điện thoại người thân.`
      );
      return;
    }

    const data = {
      dataForm,
      tamtruHuyen: selectedTamTruHuyen,
      tamtruTinh: selectedTamTruTinh,
      tamtruXa: selectedTamTruXa,
      xa: selectedThuongTruXa,
      tinh: selectedThuongTruTinh,
      stk: selectedOption === "Đã có" ? stknew : selectedOption,
      huyen: selectedThuongTruHuyen,
      tomtacbt: rows,
    };

    try {
      await axios.put(
        `https://servertd.vietlonghung.com.vn/api/updatabyid/${cccd}`,
        data
      );

      console.log(data);
      alert("Cập nhật chi tiết thành công");
      setError("");
    } catch (error) {
      setError("Lỗi khi cập nhật thông tin.");
    }
  };
  // Chặn người bấm phím Enter
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      return false;
    }
  };

  return (
    <div className="container bg-light mt-4">
      <h1 className="text-center pt-4 text-primary">TÌM KIẾM ỨNG VIÊN</h1>
      <form onKeyDown={handleKeyDown}>
        <div className="row mt-4 justify-content-around">
          <div className="col-12">
            <div className="mb-4">
              <label htmlFor="cccdSearch" className="form-label">
                Nhập Căn cước công nhân:
              </label>
              <input
                type="text"
                className="form-control"
                id="cccdSearch"
                name="cccdSearch"
                minLength="9"
                maxLength="12"
                required
                placeholder="08209xxxxxxx"
                value={cccd}
                onChange={handleInputSearchChange}
              />
            </div>
          </div>
          <div className="col-6  ">
            <button
              onClick={handleSearch}
              className="btn btn-primary w-100 mb-4 font-size-1-2"
            >
              <i className="fa-solid fa-magnifying-glass mr-3"></i>
              <span> Tìm kiếm</span>
            </button>
          </div>
        </div>

        {isLoading && (
          <div className="loading">
            <div className="spinner"></div>
          </div>
        )}

        {dataForm && !isLoading && (
          <>
            <h4 className="text-center mt-5 mb-4 text-success">
              THÔNG TIN CHI TIẾT ỨNG VIÊN
            </h4>

            <div className="row">
              <label className="col-12 mb-4 pt-3 border-top border-2 fw-bold">
                Thông tin cơ bản
              </label>
              <div className="col-3 col-lg-2 mb-4">
                <label htmlFor="fullname" className="form-label">
                  Họ tên
                </label>
              </div>
              <div className="col-9 col-lg-4 mb-4">
                <input
                  type="text"
                  className="form-control"
                  id="fullname"
                  name="fullname"
                  placeholder="Nguyễn Thành Nam"
                  required
                  value={dataForm.fullname}
                  disabled
                  onChange={handleInputChange}
                />
              </div>

              <div className="col-3 col-lg-2 mb-4">
                <label htmlFor="date" className="form-label">
                  Ngày sinh
                </label>
              </div>
              <div className="col-9 col-lg-4 mb-4">
                <input
                  type="text"
                  className="form-control single-date"
                  id="date"
                  name="date"
                  required
                  value={dataForm.date}
                  onChange={handleInputChange}
                  placeholder="dd/mm/yyyy"
                />
              </div>
            </div>

            <div class="row">
              <label for="dantoc" class="col-3 col-lg-2 col-form-label mb-4">
                Dân tộc
              </label>
              <div class="col-9 col-lg-4 mb-4">
                <select
                  id="dantoc"
                  name="dantoc"
                  required
                  value={dataForm.dantoc}
                  class="form-select"
                  onChange={handleInputChange}
                >
                  <option value="">Chọn dân tộc</option>
                  <option value="Kinh">Kinh</option>
                  <option value="Tày">Tày</option>
                  <option value="Thái">Thái</option>
                  <option value="Hoa">Hoa</option>
                  <option value="Khơ-me">Khơ-me</option>
                  <option value="Mường">Mường</option>
                  <option value="Nùng">Nùng</option>
                  <option value="HMông">HMông</option>
                  <option value="Dao">Dao</option>
                  <option value="Gia-rai">Gia-rai</option>
                  <option value="Ngái">Ngái</option>
                  <option value="Ê-đê">Ê-đê</option>
                  <option value="Ba na">Ba na</option>
                  <option value="Xơ-Đăng">Xơ-Đăng</option>
                  <option value="Sán Chay">Sán Chay</option>
                  <option value="Cơ-ho">Cơ-ho</option>
                  <option value="Chăm">Chăm</option>
                  <option value="Sán Dìu">Sán Dìu</option>
                  <option value="Hrê">Hrê</option>
                  <option value="Mnông">Mnông</option>
                  <option value="Ra-glai">Ra-glai</option>
                  <option value="Xtiêng">Xtiêng</option>
                  <option value="Bru-Vân Kiều">Bru-Vân Kiều</option>
                  <option value="Thổ">Thổ</option>
                  <option value="Giáy">Giáy</option>
                  <option value="Cơ-tu">Cơ-tu</option>
                  <option value="Gié Triêng">Gié Triêng</option>
                  <option value="Mạ">Mạ</option>
                  <option value="Khơ-mú">Khơ-mú</option>
                  <option value="Co">Co</option>
                  <option value="Tà-ôi">Tà-ôi</option>
                  <option value="Chơ-ro">Chơ-ro</option>
                  <option value="Kháng">Kháng</option>
                  <option value="Xinh-mun">Xinh-mun</option>
                  <option value="Hà Nhì">Hà Nhì</option>
                  <option value="Chu ru">Chu ru</option>
                  <option value="Lào">Lào</option>
                  <option value="La Chí">La Chí</option>
                  <option value="La Ha">La Ha</option>
                  <option value="Phù Lá">Phù Lá</option>
                  <option value="La Hủ">La Hủ</option>
                  <option value="Lự">Lự</option>
                  <option value="Lô Lô">Lô Lô</option>
                  <option value="Chứt">Chứt</option>
                  <option value="Mảng">Mảng</option>
                  <option value="Pà Thẻn">Pà Thẻn</option>
                  <option value="Co Lao">Co Lao</option>
                  <option value="Cống">Cống</option>
                  <option value="Bố Y">Bố Y</option>
                  <option value="Si La">Si La</option>
                  <option value="Pu Péo">Pu Péo</option>
                  <option value="Brâu">Brâu</option>
                  <option value="Ơ Đu">Ơ Đu</option>
                  <option value="Rơ măm">Rơ măm</option>
                </select>
                <div class="invalid-feedback">Vui lòng chọn dân tộc</div>
              </div>

              <label for="tongiao" class="col-3 col-lg-2 col-form-label mb-4">
                Tôn giáo
              </label>
              <div class="col-9 col-lg-4 mb-4">
                <select
                  class="form-select"
                  id="tongiao"
                  name="tongiao"
                  aria-label="Default select example"
                  value={dataForm.tongiao}
                  onChange={handleInputChange}
                >
                  <option value="Không">Không</option>
                  <option value="Phật Giáo">Phật Giáo</option>
                  <option value="Thiên Chúa Giáo">Thiên Chúa Giáo</option>
                  <option value="Công Giáo">Công Giáo</option>
                  <option value="Hồi Giáo">Hồi Giáo</option>
                  <option value="Tin Lành">Tin Lành</option>
                  <option value="Cao Đài">Cao Đài</option>
                  <option value="Hòa Hảo">Hòa Hảo</option>
                </select>
              </div>
            </div>

            <div class="row">
              <label for="gender" class="col-3 col-lg-2 col-form-label mb-4">
                Giới tính
              </label>
              <div class="col-9 col-lg-4 mb-4">
                <select
                  id="gender"
                  name="gender"
                  class="form-select"
                  aria-label="Default select example"
                  required
                  disabled
                  onChange={handleInputChange}
                  value={dataForm.gender}
                >
                  <option value>Chọn giới tính</option>
                  <option value="Nam">Nam</option>
                  <option value="Nữ">Nữ</option>
                </select>
                <div class="invalid-feedback">Vui lòng chọn giới tính</div>
              </div>
            </div>

            <div class="row">
              <label class="col-12 mb-4 pt-3 border-top border-2 fw-bold">
                Thông tin nơi sinh
              </label>

              <label
                htmlFor="noisinh"
                className="col-3 col-lg-2 col-form-label mb-4"
              >
                Tỉnh (thành phố)
              </label>
              <div className="col-9 col-lg-4 mb-4">
                <select
                  className="form-select"
                  id="noisinh"
                  name="noisinh"
                  value={dataForm.noisinh}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Chọn tỉnh</option>
                  {noisinhTinh1.map((province) => (
                    <option key={province.Code} value={province.FullName}>
                      {province.FullName}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">
                  Vui lòng chọn nơi sinh thuộc tỉnh (TP) nào?
                </div>
              </div>
            </div>
            <div class="row">
              <label class="col-12 mb-4 pt-3 border-top border-2 fw-bold">
                Thông tin Căn cước công dân / Chứng minh nhân dân
              </label>

              <label for="cccd" class="col-3 col-lg-2 col-form-label mb-4">
                CCCD/CMND
              </label>
              <div class="col-9 col-lg-4 mb-4">
                <input
                  type="text"
                  class="form-control"
                  id="cccd"
                  name="cccd"
                  minlength="9"
                  maxlength="12"
                  required
                  disabled
                  value={dataForm.cccd}
                  placeholder="08209xxxxxxx"
                  onChange={handleInputChange}
                />

                <div class="invalid-feedback">Vui lòng nhập CCCD/CMND</div>
              </div>
              <label
                for="ngaycapcccd"
                class="col-3 col-lg-2 col-form-label mb-4"
              >
                Ngày cấp
              </label>
              <div class="col-9 col-lg-4 mb-4">
                <DatePicker
                  selected={
                    dataForm.ngaycapcccd
                      ? moment(dataForm.ngaycapcccd, "DD/MM/YYYY").toDate()
                      : null
                  }
                  onChange={(date) => handleDateChange(date, "ngaycapcccd")}
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  placeholderText="Ngày cấp CCCD"
                  showYearDropdown
                  required
                  locale="vi"
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                />
              </div>

              <label
                for="noicapcccd"
                class="col-3 col-lg-2 col-form-label mb-4"
              >
                Nơi cấp
              </label>
              <div class="col-9 col-lg-4 mb-4">
                <select
                  className="form-select"
                  id="noicapcccd"
                  name="noicapcccd"
                  value={dataForm.noicapcccd}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Nơi cấp</option>
                  <option value="Cục cảnh sát quản lý hành chính về trật tự xã hội">
                    Cục cảnh sát quản lý hành chính về trật tự xã hội
                  </option>
                  <option value="Bộ Công An">Bộ Công An</option>
                  {noicapcccdTinh.map((province) => (
                    <option key={province.Code} value={province.FullName}>
                      {province.FullName}
                    </option>
                  ))}
                </select>
                <div class="invalid-feedback">
                  Vui lòng chọn nơi cấp CCCD/CMND
                </div>
              </div>
            </div>
            <div class="row">
              <label class="col-12 mb-4 pt-3 border-top border-2 fw-bold">
                Thông tin học vấn
              </label>

              <label for="tdvh" class="col-3 col-lg-2 col-form-label mb-4">
                Trình độ văn hóa
              </label>
              <div class="col-9 col-lg-4 mb-4">
                <select
                  id="tdvh"
                  name="tdvh"
                  disabled
                  required
                  value={dataForm.tdvh}
                  onChange={handleInputChange}
                  class="form-select"
                >
                  <option value="">Chọn trình độ văn hóa</option>
                  <option value="1">1/12</option>
                  <option value="2">2/12</option>
                  <option value="3">3/12</option>
                  <option value="4">4/12</option>
                  <option value="5">5/12</option>
                  <option value="6">6/12</option>
                  <option value="7">7/12</option>
                  <option value="8">8/12</option>
                  <option value="9">9/12</option>
                  <option value="10">10/12</option>
                  <option value="11">11/12</option>
                  <option value="12">12/12</option>
                </select>
                <div class="invalid-feedback">
                  Vui lòng chọn trình độ văn hoá
                </div>
              </div>

              <label for="chuyenmon" class="col-3 col-lg-2 col-form-label mb-4">
                Trình độ chuyên môn (nếu có)
              </label>
              <div class="col-9 col-lg-4 mb-4">
                <select
                  id="chuyenmon"
                  name="chuyenmon"
                  class="form-select"
                  value={dataForm.chuyenmon}
                  onChange={handleInputChange}
                >
                  <option value="">Chuyên Môn của Ứng Viên (nếu có)</option>
                  <option value="Trung Cấp">Trung Cấp</option>
                  <option value="Cao Đẳng">Cao Đẳng</option>
                  <option value="Đại Học">Đại Học</option>
                </select>
              </div>

              <label for="ngoaingu" class="col-3 col-lg-2 col-form-label mb-4">
                Ngoại ngữ (Nếu có)
              </label>
              <div class="col-9 col-lg-4 mb-4">
                <input
                  type="text"
                  class="form-control"
                  id="ngoaingu"
                  name="ngoaingu"
                  placeholder="Tiếng Anh/ Tiếng Pháp/ Tiếng Trung"
                  value={dataForm.ngoaingu}
                  onChange={handleInputChange}
                />
              </div>

              {dataForm.chuyenmon !== "" &&
                dataForm.chuyenmon !== "Chuyên Môn của Ứng Viên (nếu có)" && (
                  <>
                    <label
                      htmlFor="chuyennganh"
                      class="col-3 col-lg-2 col-form-label mb-4"
                    >
                      Chuyên ngành ứng viên
                    </label>
                    <div class="col-9 col-lg-4 mb-4">
                      <input
                        type="text"
                        className="form-control"
                        required
                        id="chuyennganh"
                        name="chuyennganh"
                        value={dataForm.chuyennganh}
                        onChange={handleInputChange}
                        placeholder="Ngành Quản trị kinh doanh"
                      />
                    </div>
                  </>
                )}
            </div>

            <div class="row">
              <label class="col-12 mb-4 pt-3 border-top border-2 fw-bold">
                Thông tin liên hệ
              </label>

              <label
                for="numberPhone"
                class="col-3 col-lg-2 col-form-label mb-4"
              >
                Số điện thoại di động
              </label>
              <div class="col-9 col-lg-4 mb-4">
                <input
                  type="tel"
                  id="numberPhone"
                  name="numberPhone"
                  minlength="10"
                  maxlength="10"
                  class="form-control"
                  value={dataForm.numberPhone}
                  onChange={handleInputChange}
                  required
                  placeholder="035977xxxx"
                />
                <div class="invalid-feedback">
                  Số điện thoại di động nhập đủ 10 số
                </div>
              </div>

              <label for="email" class="col-3 col-lg-2 col-form-label mb-4">
                Email (nếu có)
              </label>
              <div class="col-9 col-lg-4 mb-4">
                <input
                  type="email"
                  id="email"
                  onChange={handleInputChange}
                  name="email"
                  value={dataForm.email}
                  class="form-control "
                  placeholder="nguyenvana@gmail.com"
                />
                <div class="invalid-feedback">
                  Nhập đúng định dạng email. Ví dụ: nguyenvana@gmail.com
                </div>
              </div>
            </div>

            <div className="row">
              <label className="col-12 mb-4 pt-3 border-top border-2 fw-bold">
                Địa chỉ thường trú
              </label>

              <label
                htmlFor="thuongtru-province"
                className="col-3 col-lg-2 col-form-label mb-4"
              >
                Tỉnh (thành phố)
              </label>
              <div className="col-9 col-lg-4 mb-4">
                <select
                  className="form-select"
                  id="thuongtru-province"
                  name="thuongtru-province"
                  onChange={handleThuongTruTinh}
                  value={selectedThuongTruTinh}
                  required
                >
                  <option value="">Chọn tỉnh</option>
                  {provinces2.map((province) => (
                    <option key={province.Code} value={province.FullName}>
                      {province.FullName}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">
                  Vui lòng chọn địa chỉ thường trú thuộc tỉnh (TP) nào?
                </div>
              </div>

              {selectedThuongTruTinh && (
                <>
                  <label
                    htmlFor="thuongtru-district"
                    className="col-3 col-lg-2 col-form-label mb-4 ps-lg-3"
                  >
                    Quận (huyện)
                  </label>
                  <div className="col-9 col-lg-4 mb-4">
                    <select
                      className="form-select"
                      id="thuongtru-district"
                      name="thuongtru-district"
                      value={selectedThuongTruHuyen}
                      onChange={handleThuongTruHuyenChange}
                      required
                    >
                      <option value="">Chọn quận/huyện</option>
                      {districts2.map((district) => (
                        <option
                          selected=""
                          key={district.Code}
                          value={district.FullName}
                        >
                          {district.FullName}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      Vui lòng chọn nơi sinh thuộc quận (huyện) nào?
                    </div>
                  </div>
                </>
              )}

              {selectedThuongTruHuyen && (
                <>
                  <label
                    htmlFor="thuongtru-ward"
                    className="col-3 col-lg-2 col-form-label mb-4"
                  >
                    Phường (xã)
                  </label>
                  <div className="col-9 col-lg-4 mb-4">
                    <select
                      className="form-select"
                      id="thuongtru-ward"
                      name="thuongtru-ward"
                      value={selectedThuongTruXa}
                      onChange={handleThuongTruXa}
                      required
                    >
                      <option value="">Chọn phường/xã</option>
                      {wards2.map((ward) => (
                        <option key={ward.Code} value={ward.FullName}>
                          {ward.FullName}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      Vui lòng chọn nơi sinh thuộc phường (xã) nào?
                    </div>
                  </div>
                </>
              )}

              {selectedThuongTruXa && (
                <>
                  <label
                    htmlFor="sonha"
                    className="col-3 col-lg-2 col-form-label mb-4"
                  >
                    Ấp/Khu phố
                  </label>
                  <div className="col-9 col-lg-4 mb-4">
                    <input
                      className="form-control"
                      id="sonha"
                      name="sonha"
                      value={dataForm.sonha}
                      onChange={handleInputChange}
                      required
                    />
                    <div className="invalid-feedback">
                      Vui lòng nhập số nhà của ứng viên
                    </div>
                  </div>
                </>
              )}
            </div>

            {selectedThuongTruTinh !== "" &&
              selectedThuongTruTinh !== "Tỉnh Tiền Giang" && (
                <div className="row">
                  <label className="col-12 mb-4 pt-3 border-top border-2 fw-bold">
                    Địa chỉ tạm trú (nếu địa chỉ thường trú khác Tỉnh Tiền
                    Giang)
                  </label>

                  <label
                    htmlFor="tamtru-province"
                    className="col-3 col-lg-2 col-form-label mb-4"
                  >
                    Tỉnh (thành phố)
                  </label>
                  <div className="col-9 col-lg-4 mb-4">
                    <select
                      className="form-select"
                      id="tamtru-province"
                      name="tamtru-province"
                      value={selectedTamTruTinh}
                      onChange={handleTamTruTinh}
                      required
                    >
                      <option value="">Chọn tỉnh</option>
                      <option value="Tỉnh Tiền Giang">Tỉnh Tiền Giang</option>
                    </select>
                    <div className="invalid-feedback">
                      Vui lòng chọn địa chỉ tạm trú thuộc tỉnh (TP) nào?
                    </div>
                  </div>

                  {selectedTamTruTinh && (
                    <>
                      <label
                        htmlFor="tamtru-district"
                        className="col-3 col-lg-2 col-form-label mb-4 ps-lg-3"
                      >
                        Quận (huyện)
                      </label>
                      <div className="col-9 col-lg-4 mb-4">
                        <select
                          className="form-select"
                          id="tamtru-district"
                          name="tamtru-district"
                          value={selectedTamTruHuyen}
                          onChange={handleTamTruHuyenChange}
                          required
                        >
                          <option value="">Chọn quận/huyện</option>
                          {districts1.map((district) => (
                            <option
                              key={district.Code}
                              value={district.FullName}
                            >
                              {district.FullName}
                            </option>
                          ))}
                        </select>
                        <div className="invalid-feedback">
                          Vui lòng chọn nơi sinh thuộc quận (huyện) nào?
                        </div>
                      </div>
                    </>
                  )}

                  {selectedTamTruHuyen && (
                    <>
                      <label
                        htmlFor="tamtru-ward"
                        className="col-3 col-lg-2 col-form-label mb-4"
                      >
                        Phường (xã)
                      </label>
                      <div className="col-9 col-lg-4 mb-4">
                        <select
                          className="form-select"
                          id="tamtru-ward"
                          name="tamtru-ward"
                          value={selectedTamTruXa}
                          onChange={handleTamTruXa}
                          required
                        >
                          <option value="">Chọn phường/xã</option>
                          {wards1.map((ward) => (
                            <option key={ward.Code} value={ward.FullName}>
                              {ward.FullName}
                            </option>
                          ))}
                        </select>
                        <div className="invalid-feedback">
                          Vui lòng chọn nơi sinh thuộc phường (xã) nào?
                        </div>
                      </div>
                    </>
                  )}
                  {selectedTamTruXa && (
                    <>
                      <label
                        htmlFor="sonha"
                        className="col-3 col-lg-2 col-form-label mb-4"
                      >
                        Số nhà
                      </label>
                      <div className="col-9 col-lg-4 mb-4">
                        <input
                          className="form-control"
                          id="sonhatamtru"
                          name="sonhatamtru"
                          value={dataForm.sonhatamtru}
                          onChange={handleInputChange}
                          required
                        />
                        <div className="invalid-feedback">
                          Vui lòng nhập số nhà của ứng viên
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            <div class="row">
              <label class="col-12 mb-4 pt-3 border-top border-2 fw-bold">
                Thông tin hộ khẩu
              </label>

              <label for="hotench" class="col-3 col-lg-2 col-form-label mb-4">
                Họ tên chủ hộ
              </label>
              <div class="col-9 col-lg-4 mb-4">
                <input
                  type="text"
                  id="hotench"
                  name="hotench"
                  value={dataForm.hotench}
                  onChange={handleInputChange}
                  class="form-control"
                  required
                  placeholder="Nguyễn Văn H"
                />
                <div class="invalid-feedback">Vui lòng nhập tên chủ hộ</div>
              </div>

              <label
                for="ngaysinhch"
                class="col-3 col-lg-2 col-form-label mb-4"
              >
                Ngày sinh chủ hộ
              </label>
              <div class="col-9 col-lg-4 mb-4">
                <DatePicker
                  maxDate={maxDate}
                  selected={
                    dataForm.ngaysinhch
                      ? moment(dataForm.ngaysinhch, "DD/MM/YYYY").toDate()
                      : null
                  }
                  onChange={(date) => handleDateChange(date, "ngaysinhch")}
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  placeholderText="Ngày sinh chủ hộ"
                  showYearDropdown
                  required
                  locale="vi"
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                />
              </div>

              <label
                for="moiquanhech"
                class="col-3 col-lg-2 col-form-label mb-4 ps-lg-3"
              >
                Mối quan hệ với chủ hộ
              </label>
              <div class="col-9 col-lg-4 mb-4">
                <select
                  class="form-select"
                  id="moiquanhech"
                  name="moiquanhech"
                  value={dataForm.moiquanhech}
                  onChange={handleInputChange}
                  aria-label="Default select example"
                  required
                >
                  <option value>Chọn mối quan hệ</option>
                  <option value="Ông">Ông</option>
                  <option value="Bà">Bà</option>
                  <option value="Bố">Bố</option>
                  <option value="Mẹ">Mẹ</option>
                  <option value="Vợ/Chồng">Vợ/Chồng</option>
                  <option value="Anh">Anh</option>
                  <option value="Chị">Chị</option>
                  <option value="Em">Em</option>
                </select>
                <div class="invalid-feedback">
                  Vui lòng chọn mối quan hệ chủ hộ
                </div>
              </div>
            </div>

            <div class="row">
              <label className="col-12 mb-4 pt-3 border-top border-2 fw-bold">
                Thông tin tài khoản ATM Vietcombank
              </label>

              <label
                htmlFor="check-acc-bank"
                className="col-3 col-lg-2 col-form-label mb-4"
              >
                Anh chị đã có thẻ ATM Vietcombank chưa?
              </label>
              <div className="col-9 col-lg-4 mb-4">
                <select
                  className="form-select"
                  id="check-acc-bank"
                  name="check-acc-bank"
                  aria-label="Default select example"
                  required
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  <option value="">Lựa chọn</option>
                  <option value="Đã có">Đã có</option>
                  <option value="Đã có, nhưng đã mất hoặc bị khoá thẻ">
                    Đã có, nhưng đã mất hoặc bị khoá thẻ
                  </option>
                  <option value="Chưa có">Chưa có</option>
                </select>
                <div className="invalid-feedback">
                  Vui lòng chọn thông tin thẻ ATM Vietcombank
                </div>
              </div>

              {selectedOption === "Đã có" && (
                <>
                  <label
                    htmlFor="stk"
                    className="col-3 col-lg-2 col-form-label mb-4"
                  >
                    Nhập số tài khoản
                  </label>
                  <div className="col-9 col-lg-4 mb-4">
                    <input
                      type="text"
                      minLength="10"
                      maxLength="13"
                      className="form-control"
                      id="stk"
                      name="stk"
                      placeholder="007xxxxxxxxxxxxx (13 chữ số)"
                      required
                      value={stks}
                      onChange={handleInputAccoutChange}
                    />
                    <div className="invalid-feedback">
                      Vui lòng nhập số tài khoản đủ 13 số
                    </div>
                  </div>
                </>
              )}
            </div>

            <div class="row">
              <label class="col-12 mb-4 pt-3 border-top border-2 fw-bold">
                Thông tin ứng tuyển
              </label>

              <label for="taynghe" class="col-3 col-lg-2 col-form-label mb-4">
                Anh chị đã có tay nghề về may chưa?
              </label>
              <div class="col-9 col-lg-4 mb-4">
                <select
                  class="form-select"
                  id="taynghe"
                  name="taynghe"
                  aria-label="Default select example"
                  required
                  disabled
                  value={dataForm.taynghe}
                  onChange={handleInputChange}
                >
                  <option value="">Tay nghề của Ứng viên</option>
                  <option value="Biết may">Biết May</option>
                  <option value="Không biết may">Không biết may</option>
                </select>
                <div class="invalid-feedback">Vui lòng chọn tay nghề</div>
              </div>

              <label for="vtut" class="col-3 col-lg-2 col-form-label mb-4">
                Vị trí ứng tuyển
              </label>
              <div class="col-9 col-lg-4 mb-4">
                <select
                  id="vtut"
                  name="vtut"
                  required
                  disabled
                  value={dataForm.vtut}
                  onChange={handleInputChange}
                  class="form-select"
                >
                  <option value="">Chọn vị trí ứng tuyển</option>
                  <option value="Công nhân may">Công nhân may</option>
                  <option value="Công nhân cắt">Công nhân cắt</option>
                  <option value="Công nhân ủi">Công nhân ủi</option>
                  <option value="Đóng gói">Đóng gói</option>
                  <option value="Kiểm hóa">Kiểm hóa</option>
                  <option value="Nhân viên kho">Nhân viên kho</option>
                  <option value="Nhân viên cơ điện">Nhân viên cơ điện</option>
                  <option value="Bảo vệ">Bảo vệ</option>
                  <option value="Nhân viên Văn phòng">
                    Nhân viên Văn phòng
                  </option>
                </select>
                <div class="invalid-feedback">
                  Vui lòng chọn vị trí ứng tuyển
                </div>
              </div>

              <label for="tttd" class="col-3 col-lg-2 col-form-label mb-4">
                Bạn biết thông tin tuyển dụng qua đâu?
              </label>
              <div class="col-9 col-lg-4 mb-4">
                <select
                  id="tttd"
                  name="tttd"
                  disabled
                  onChange={handleInputChange}
                  value={dataForm.tttd}
                  class="form-select"
                >
                  <option value="">
                    Bạn biết thông tin tuyển dụng qua đâu
                  </option>
                  <option value="Banner cổng bảo vệ">Banner cổng bảo vệ</option>
                  <option value="Banner facebook">Banner facebook</option>
                  <option value="Ứng viên tự đến công ty">
                    Ứng viên tự đến công ty
                  </option>
                  <option value="Người thân giới thiệu">
                    Người thân giới thiệu
                  </option>
                  <option value="Băng rôn">Băng rôn</option>
                  <option value="Kênh khác">Kênh khác</option>
                </select>
                <div class="invalid-feedback">Vui lòng chọn thông tin</div>
              </div>

              {dataForm.tttd === "Người thân giới thiệu" && (
                <>
                  <label for="sothe" class="col-3 col-lg-2 col-form-label mb-4">
                    Số thẻ người thân giới thiệu (nếu có)
                  </label>
                  <div class="col-9 col-lg-4 mb-4">
                    <input
                      id="sothe"
                      name="sothe"
                      value={dataForm.sothe}
                      onChange={handleInputChange}
                      class="form-control"
                    />

                    <div class="invalid-feedback">
                      Vui lòng chọn vị trí ứng tuyển
                    </div>
                  </div>
                </>
              )}
            </div>

            <h5 class="text-center mt-5 mb-4 text-success">
              KHẢO SÁT THÔNG TIN TUYỂN DỤNG
            </h5>

            <div className="row">
              <label className="col-12 mb-4 pt-3 border-top border-2 fw-bold">
                I. Quá trình bản thân (tóm tắt từ lúc 12 tuổi đến nay làm gì? ở
                đâu?)
              </label>
              <label className="more-note">
                Thời gian (Ví dụ: Từ 08/2020 đến 07/2024)
                <br />
                Công việc - Tên Công Ty - Tỉnh/Thành Phố (Ví dụ: Công nhân
                may-Công Ty TNHH ABC-Tiền Giang)
              </label>
              <table className="table table-responsive table-bordered responsiveTable">
                <thead className="text-center">
                  <tr>
                    <th>
                      Thời gian
                      <br />
                      (Ví dụ: Từ 08/2020 đến 07/2024)
                    </th>
                    <th>
                      Công việc - Tên Công Ty - Tỉnh/Thành Phố
                      <br />
                      (Ví dụ: Công nhân may-Công Ty TNHH ABC-Tiền Giang)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, index) => (
                    <tr key={index}>
                      <td data-label="Thời gian">
                        <input
                          type="text"
                          className="form-control"
                          name="thoigian"
                          value={row.thoigian}
                          onChange={(e) => handleRowChange(index, e)}
                          placeholder="Ví dụ: 12/2021 - 7/2024"
                        />
                        {!row.thoigian && (
                          <div className="invalid-feedback">
                            Vui lòng điền thời gian.
                          </div>
                        )}
                      </td>
                      <td data-label="Công việc - Tên Công Ty - Tỉnh/Thành Phố">
                        <input
                          type="text"
                          className="form-control"
                          name="congviec"
                          value={row.congviec}
                          onChange={(e) => handleRowChange(index, e)}
                          placeholder="Ví dụ: Học trường THCS Võ Văn Dánh"
                        />
                        {!row.congviec && (
                          <div className="invalid-feedback">
                            Vui lòng nhập Công việc - Tên Công Ty - Tỉnh/Thành
                            Phố.
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="3">
                      <button
                        type="button"
                        id="addrow"
                        className="btn btn-success w-100 mb-4 font-size-1-2 add-new-tr"
                        onClick={handleAddRow}
                      >
                        Thêm dòng mới
                      </button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div class="row">
              <label class="col-12 mb-4 pt-3 border-top border-2 fw-bold">
                II. Quan hệ gia đình
              </label>
              <table className="table table-responsive table-bordered responsiveTable">
                <thead className="text-center">
                  <tr>
                    <th>Mối quan hệ</th>
                    <th>Họ tên</th>
                    <th>Ngày sinh</th>
                    <th>Nghề nghiệp</th>
                    <th>Chỗ ở hiện tại</th>
                    <th>Số điện thoại</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Mối quan hệ">
                      <input
                        name="ong"
                        value={dataForm.ong}
                        disabled
                        className="form-control"
                      />

                      <div className="invalid-feedback">
                        Vui lòng chọn mối quan hệ
                      </div>
                    </td>
                    <td data-label="Họ tên">
                      <input
                        type="text"
                        className="form-control"
                        name="hotenong"
                        value={dataForm.hotenong}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Nguyễn Văn Nam"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập họ tên
                      </div>
                    </td>
                    <td data-label="Ngày sinh">
                      <DatePicker
                        scrollableYearDropdown
                        maxDate={maxDate}
                        selected={
                          dataForm.ngaysinhong
                            ? moment(
                                dataForm.ngaysinhong,
                                "DD/MM/YYYY"
                              ).toDate()
                            : null
                        }
                        onChange={(date) =>
                          handleDateChange(date, "ngaysinhong")
                        }
                        showYearDropdown
                        locale="vi"
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Chọn ngày sinh"
                      />
                      {dataForm.ngaysinhong && (
                        <div className="invalid-feedback">
                          Vui lòng nhập ngày sinh
                        </div>
                      )}
                    </td>
                    <td data-label="Nghề nghiệp">
                      <input
                        type="text"
                        className="form-control"
                        name="nghenghiepong"
                        value={dataForm.nghenghiepong}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Buôn bán"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập nghề nghiệp
                      </div>
                    </td>
                    <td data-label="Chỗ ở hiện tại">
                      <input
                        type="text"
                        className="form-control"
                        name="chooong"
                        value={dataForm.chooong}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: 63 đường 30/4 thị trấn Tân Hoà, ..."
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập chỗ ở hiện tại
                      </div>
                    </td>
                    <td data-label="Số điện thoại">
                      <input
                        type="tel"
                        minlength="10"
                        maxlength="10"
                        class="form-control"
                        name="sdtong"
                        value={dataForm.sdtong}
                        onChange={handleInputChange}
                        placeholder="Số điện thoại"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập Số điện thoại
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Mối quan hệ">
                      <input
                        name="ba"
                        value={dataForm.ba}
                        required
                        disabled
                        className="form-control"
                      />
                      <div className="invalid-feedback">
                        Vui lòng chọn mối quan hệ
                      </div>
                    </td>
                    <td data-label="Họ tên">
                      <input
                        type="text"
                        className="form-control"
                        name="hotenba"
                        value={dataForm.hotenba}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Nguyễn Văn Nam"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập họ tên
                      </div>
                    </td>
                    <td data-label="Ngày sinh">
                      <DatePicker
                        scrollableYearDropdown
                        maxDate={maxDate}
                        selected={
                          dataForm.ngaysinhba
                            ? moment(dataForm.ngaysinhba, "DD/MM/YYYY").toDate()
                            : null
                        }
                        onChange={(date) =>
                          handleDateChange(date, "ngaysinhba")
                        }
                        showYearDropdown
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Chọn ngày sinh"
                      />
                      {dataForm.ngaysinhba && (
                        <div className="invalid-feedback">
                          Vui lòng nhập ngày sinh
                        </div>
                      )}
                    </td>
                    <td data-label="Nghề nghiệp">
                      <input
                        type="text"
                        className="form-control"
                        name="nghenghiepba"
                        value={dataForm.nghenghiepba}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Buôn bán"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập nghề nghiệp
                      </div>
                    </td>
                    <td data-label="Chỗ ở hiện tại">
                      <input
                        type="text"
                        className="form-control"
                        name="chooba"
                        value={dataForm.chooba}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: 63 đường 30/4 thị trấn Tân Hoà, ..."
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập chỗ ở hiện tại
                      </div>
                    </td>
                    <td data-label="Số điện thoại">
                      <input
                        type="tel"
                        minlength="10"
                        maxlength="10"
                        class="form-control"
                        name="sdtba"
                        value={dataForm.sdtba}
                        onChange={handleInputChange}
                        placeholder="Số điện thoại"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập Số điện thoại
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Mối quan hệ">
                      <input
                        name="cha"
                        value={dataForm.cha}
                        disabled
                        className="form-control"
                      />

                      <div className="invalid-feedback">
                        Vui lòng chọn mối quan hệ
                      </div>
                    </td>
                    <td data-label="Họ tên">
                      <input
                        type="text"
                        className="form-control"
                        name="hotencha"
                        value={dataForm.hotencha}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Nguyễn Văn Nam"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập họ tên
                      </div>
                    </td>
                    <td data-label="Ngày sinh">
                      <DatePicker
                        scrollableYearDropdown
                        showYearDropdown
                        maxDate={maxDate}
                        selected={
                          dataForm.ngaysinhcha
                            ? moment(
                                dataForm.ngaysinhcha,
                                "DD/MM/YYYY"
                              ).toDate()
                            : null
                        }
                        onChange={(date) =>
                          handleDateChange(date, "ngaysinhcha")
                        }
                        locale="vi"
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Chọn ngày sinh"
                      />
                      {dataForm.ngaysinhcha && (
                        <div className="invalid-feedback">
                          Vui lòng nhập ngày sinh
                        </div>
                      )}
                    </td>
                    <td data-label="Nghề nghiệp">
                      <input
                        type="text"
                        className="form-control"
                        name="nghenghiepcha"
                        value={dataForm.nghenghiepcha}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Buôn bán"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập nghề nghiệp
                      </div>
                    </td>
                    <td data-label="Chỗ ở hiện tại">
                      <input
                        type="text"
                        className="form-control"
                        name="choocha"
                        value={dataForm.choocha}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: 63 đường 30/4 thị trấn Tân Hoà, ..."
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập chỗ ở hiện tại
                      </div>
                    </td>
                    <td data-label="Số điện thoại">
                      <input
                        type="tel"
                        minlength="10"
                        maxlength="10"
                        class="form-control"
                        name="sdtcha"
                        value={dataForm.sdtcha}
                        onChange={handleInputChange}
                        placeholder="Số điện thoại"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập Số điện thoại
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Mối quan hệ">
                      <input
                        name="me"
                        value={dataForm.me}
                        disabled
                        className="form-control"
                      />
                      <div className="invalid-feedback">
                        Vui lòng chọn mối quan hệ
                      </div>
                    </td>
                    <td data-label="Họ tên">
                      <input
                        type="text"
                        className="form-control"
                        name="hotenme"
                        value={dataForm.hotenme}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Nguyễn Văn Nam"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập họ tên
                      </div>
                    </td>
                    <td data-label="Ngày sinh">
                      <DatePicker
                        scrollableYearDropdown
                        showYearDropdown
                        maxDate={maxDate}
                        selected={
                          dataForm.ngaysinhme
                            ? moment(dataForm.ngaysinhme, "DD/MM/YYYY").toDate()
                            : null
                        }
                        onChange={(date) =>
                          handleDateChange(date, "ngaysinhme")
                        }
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Chọn ngày sinh"
                      />
                      {dataForm.ngaysinhme && (
                        <div className="invalid-feedback">
                          Vui lòng nhập ngày sinh
                        </div>
                      )}
                    </td>
                    <td data-label="Nghề nghiệp">
                      <input
                        type="text"
                        className="form-control"
                        name="nghenghiepme"
                        value={dataForm.nghenghiepme}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Buôn bán"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập nghề nghiệp
                      </div>
                    </td>
                    <td data-label="Chỗ ở hiện tại">
                      <input
                        type="text"
                        className="form-control"
                        name="choome"
                        value={dataForm.choome}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: 63 đường 30/4 thị trấn Tân Hoà, ..."
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập chỗ ở hiện tại
                      </div>
                    </td>
                    <td data-label="Số điện thoại">
                      <input
                        type="tel"
                        minlength="10"
                        maxlength="10"
                        class="form-control"
                        name="sdtme"
                        value={dataForm.sdtme}
                        onChange={handleInputChange}
                        placeholder="Số điện thoại"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập Số điện thoại
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Mối quan hệ">
                      <input
                        name="chongvo"
                        value={dataForm.chongvo}
                        className="form-control"
                        disabled
                      />
                      <div className="invalid-feedback">
                        Vui lòng chọn mối quan hệ
                      </div>
                    </td>
                    <td data-label="Họ tên">
                      <input
                        type="text"
                        className="form-control"
                        name="hotenchongvo"
                        value={dataForm.hotenchongvo}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Nguyễn Văn Nam"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập họ tên
                      </div>
                    </td>
                    <td data-label="Ngày sinh">
                      <DatePicker
                        scrollableYearDropdown
                        showYearDropdown
                        maxDate={maxDate}
                        selected={
                          dataForm.ngaysinhchongvo
                            ? moment(
                                dataForm.ngaysinhchongvo,
                                "DD/MM/YYYY"
                              ).toDate()
                            : null
                        }
                        onChange={(date) =>
                          handleDateChange(date, "ngaysinhchongvo")
                        }
                        locale="vi"
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Chọn ngày sinh"
                      />
                      {dataForm.ngaysinhchongvo && (
                        <div className="invalid-feedback">
                          Vui lòng nhập ngày sinh
                        </div>
                      )}
                    </td>
                    <td data-label="Nghề nghiệp">
                      <input
                        type="text"
                        className="form-control"
                        name="nghenghiepchongvo"
                        value={dataForm.nghenghiepchongvo}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Buôn bán"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập nghề nghiệp
                      </div>
                    </td>
                    <td data-label="Chỗ ở hiện tại">
                      <input
                        type="text"
                        className="form-control"
                        name="choochongvo"
                        onChange={handleInputChange}
                        value={dataForm.choochongvo}
                        placeholder="Ví dụ: 63 đường 30/4 thị trấn Tân Hoà, ..."
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập chỗ ở hiện tại
                      </div>
                    </td>
                    <td data-label="Số điện thoại">
                      <input
                        type="tel"
                        minlength="10"
                        maxlength="10"
                        class="form-control"
                        name="sdtchongvo"
                        value={dataForm.sdtchongvo}
                        onChange={handleInputChange}
                        placeholder="Số điện thoại"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập Số điện thoại
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Mối quan hệ">
                      <input
                        name="con1"
                        value={dataForm.con1}
                        disabled
                        className="form-control"
                      />
                      <div className="invalid-feedback">
                        Vui lòng chọn mối quan hệ
                      </div>
                    </td>
                    <td data-label="Họ tên">
                      <input
                        type="text"
                        className="form-control"
                        name="hotencon1"
                        value={dataForm.hotencon1}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Nguyễn Văn Nam"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập họ tên
                      </div>
                    </td>
                    <td data-label="Ngày sinh">
                      <DatePicker
                        scrollableYearDropdown
                        showYearDropdown
                        selected={
                          dataForm.ngaysinhcon1
                            ? moment(
                                dataForm.ngaysinhcon1,
                                "DD/MM/YYYY"
                              ).toDate()
                            : null
                        }
                        onChange={(date) =>
                          handleDateChange(date, "ngaysinhcon1")
                        }
                        locale="vi"
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Chọn ngày sinh"
                      />
                      {dataForm.ngaysinhcon1 && (
                        <div className="invalid-feedback">
                          Vui lòng nhập ngày sinh
                        </div>
                      )}
                    </td>
                    <td data-label="Nghề nghiệp">
                      <input
                        type="text"
                        className="form-control"
                        name="nghenghiepcon1"
                        value={dataForm.nghenghiepcon1}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Buôn bán"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập nghề nghiệp
                      </div>
                    </td>
                    <td data-label="Chỗ ở hiện tại">
                      <input
                        type="text"
                        className="form-control"
                        name="choocon1"
                        value={dataForm.choocon1}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: 63 đường 30/4 thị trấn Tân Hoà, ..."
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập chỗ ở hiện tại
                      </div>
                    </td>
                    <td data-label="Số điện thoại">
                      <input
                        type="tel"
                        minlength="10"
                        maxlength="10"
                        class="form-control"
                        name="sdtcon1"
                        value={dataForm.sdtcon1}
                        onChange={handleInputChange}
                        placeholder="Số điện thoại"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập Số điện thoại
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Mối quan hệ">
                      <input
                        name="con2"
                        value={dataForm.con2}
                        disabled
                        className="form-control"
                      />
                      <div className="invalid-feedback">
                        Vui lòng chọn mối quan hệ
                      </div>
                    </td>
                    <td data-label="Họ tên">
                      <input
                        type="text"
                        className="form-control"
                        name="hotencon2"
                        value={dataForm.hotencon2}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Nguyễn Văn Nam"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập họ tên
                      </div>
                    </td>
                    <td data-label="Ngày sinh">
                      <DatePicker
                        scrollableYearDropdown
                        showYearDropdown
                        selected={
                          dataForm.ngaysinhcon2
                            ? moment(
                                dataForm.ngaysinhcon2,
                                "DD/MM/YYYY"
                              ).toDate()
                            : null
                        }
                        onChange={(date) =>
                          handleDateChange(date, "ngaysinhcon2")
                        }
                        locale="vi"
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Chọn ngày sinh"
                      />
                      {dataForm.ngaysinhcon2 && (
                        <div className="invalid-feedback">
                          Vui lòng nhập ngày sinh
                        </div>
                      )}
                    </td>
                    <td data-label="Nghề nghiệp">
                      <input
                        type="text"
                        className="form-control"
                        name="nghenghiepcon2"
                        value={dataForm.nghenghiepcon2}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Buôn bán"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập nghề nghiệp
                      </div>
                    </td>
                    <td data-label="Chỗ ở hiện tại">
                      <input
                        type="text"
                        className="form-control"
                        name="choocon2"
                        value={dataForm.choocon2}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: 63 đường 30/4 thị trấn Tân Hoà, ..."
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập chỗ ở hiện tại
                      </div>
                    </td>
                    <td data-label="Số điện thoại">
                      <input
                        type="tel"
                        minlength="10"
                        maxlength="10"
                        class="form-control"
                        name="sdtcon2"
                        value={dataForm.sdtcon2}
                        onChange={handleInputChange}
                        placeholder="Số điện thoại"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập Số điện thoại
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Mối quan hệ">
                      <input
                        name="con3"
                        value={dataForm.con3}
                        disabled
                        className="form-control"
                      />
                      <div className="invalid-feedback">
                        Vui lòng chọn mối quan hệ
                      </div>
                    </td>
                    <td data-label="Họ tên">
                      <input
                        type="text"
                        className="form-control"
                        name="hotencon3"
                        value={dataForm.hotencon3}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Nguyễn Văn Nam"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập họ tên
                      </div>
                    </td>
                    <td data-label="Ngày sinh">
                      <DatePicker
                        scrollableYearDropdown
                        showYearDropdown
                        selected={
                          dataForm.ngaysinhcon3
                            ? moment(
                                dataForm.ngaysinhcon3,
                                "DD/MM/YYYY"
                              ).toDate()
                            : null
                        }
                        onChange={(date) =>
                          handleDateChange(date, "ngaysinhcon3")
                        }
                        locale="vi"
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Chọn ngày sinh"
                      />
                      {dataForm.ngaysinhcon3 && (
                        <div className="invalid-feedback">
                          Vui lòng nhập ngày sinh
                        </div>
                      )}
                    </td>
                    <td data-label="Nghề nghiệp">
                      <input
                        type="text"
                        className="form-control"
                        name="nghenghiepcon3"
                        value={dataForm.nghenghiepcon3}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Buôn bán"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập nghề nghiệp
                      </div>
                    </td>
                    <td data-label="Chỗ ở hiện tại">
                      <input
                        type="text"
                        className="form-control"
                        name="choocon3"
                        value={dataForm.choocon3}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: 63 đường 30/4 thị trấn Tân Hoà, ..."
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập chỗ ở hiện tại
                      </div>
                    </td>
                    <td data-label="Số điện thoại">
                      <input
                        type="tel"
                        minlength="10"
                        maxlength="10"
                        class="form-control"
                        name="sdtcon3"
                        value={dataForm.sdtcon3}
                        onChange={handleInputChange}
                        placeholder="Số điện thoại"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập Số điện thoại
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Mối quan hệ">
                      <input
                        name="chavo"
                        value={dataForm.chavo}
                        disabled
                        className="form-control"
                      />
                      <div className="invalid-feedback">
                        Vui lòng chọn mối quan hệ
                      </div>
                    </td>
                    <td data-label="Họ tên">
                      <input
                        type="text"
                        className="form-control"
                        name="hotenchavo"
                        value={dataForm.hotenchavo}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Nguyễn Văn Nam"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập họ tên
                      </div>
                    </td>
                    <td data-label="Ngày sinh">
                      <DatePicker
                        scrollableYearDropdown
                        showYearDropdown
                        maxDate={maxDate}
                        selected={
                          dataForm.ngaysinhchavo
                            ? moment(
                                dataForm.ngaysinhchavo,
                                "DD/MM/YYYY"
                              ).toDate()
                            : null
                        }
                        onChange={(date) =>
                          handleDateChange(date, "ngaysinhchavo")
                        }
                        locale="vi"
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Chọn ngày sinh"
                      />
                      {dataForm.ngaysinhchavo && (
                        <div className="invalid-feedback">
                          Vui lòng nhập ngày sinh
                        </div>
                      )}
                    </td>
                    <td data-label="Nghề nghiệp">
                      <input
                        type="text"
                        className="form-control"
                        name="nghenghiepchavo"
                        value={dataForm.nghenghiepchavo}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Buôn bán"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập nghề nghiệp
                      </div>
                    </td>
                    <td data-label="Chỗ ở hiện tại">
                      <input
                        type="text"
                        className="form-control"
                        name="choochavo"
                        value={dataForm.choochavo}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: 63 đường 30/4 thị trấn Tân Hoà, ..."
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập chỗ ở hiện tại
                      </div>
                    </td>
                    <td data-label="Số điện thoại">
                      <input
                        type="tel"
                        minlength="10"
                        maxlength="10"
                        class="form-control"
                        name="sdtchavo"
                        value={dataForm.sdtchavo}
                        onChange={handleInputChange}
                        placeholder="Số điện thoại"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập Số điện thoại
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Mối quan hệ">
                      <input
                        name="mevochong"
                        value={dataForm.mevo}
                        disabled
                        className="form-control"
                      />
                      <div className="invalid-feedback">
                        Vui lòng chọn mối quan hệ
                      </div>
                    </td>
                    <td data-label="Họ tên">
                      <input
                        type="text"
                        className="form-control"
                        name="hotenmevo"
                        value={dataForm.hotenmevo}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Nguyễn Văn Nam"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập họ tên
                      </div>
                    </td>
                    <td data-label="Ngày sinh">
                      <DatePicker
                        scrollableYearDropdown
                        showYearDropdown
                        maxDate={maxDate}
                        selected={
                          dataForm.ngaysinhmevo
                            ? moment(
                                dataForm.ngaysinhmevo,
                                "DD/MM/YYYY"
                              ).toDate()
                            : null
                        }
                        onChange={(date) =>
                          handleDateChange(date, "ngaysinhmevo")
                        }
                        locale="vi"
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Chọn ngày sinh"
                      />
                      {dataForm.ngaysinhmevo && (
                        <div className="invalid-feedback">
                          Vui lòng nhập ngày sinh
                        </div>
                      )}
                    </td>
                    <td data-label="Nghề nghiệp">
                      <input
                        type="text"
                        className="form-control"
                        name="nghenghiepmevo"
                        value={dataForm.nghenghiepmevo}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Buôn bán"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập nghề nghiệp
                      </div>
                    </td>
                    <td data-label="Chỗ ở hiện tại">
                      <input
                        type="text"
                        className="form-control"
                        name="choomevo"
                        value={dataForm.choomevo}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: 63 đường 30/4 thị trấn Tân Hoà, ..."
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập chỗ ở hiện tại
                      </div>
                    </td>
                    <td data-label="Số điện thoại">
                      <input
                        type="tel"
                        minlength="10"
                        maxlength="10"
                        class="form-control"
                        name="sdtmevo"
                        value={dataForm.sdtmevo}
                        onChange={handleInputChange}
                        placeholder="Số điện thoại"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập Số điện thoại
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Mối quan hệ">
                      <input
                        name="a1"
                        value={dataForm.a1}
                        disabled
                        className="form-control"
                      />
                      <div className="invalid-feedback">
                        Vui lòng chọn mối quan hệ
                      </div>
                    </td>
                    <td data-label="Họ tên">
                      <input
                        type="text"
                        className="form-control"
                        name="hotena1"
                        value={dataForm.hotena1}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Nguyễn Văn Nam"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập họ tên
                      </div>
                    </td>
                    <td data-label="Ngày sinh">
                      <DatePicker
                        scrollableYearDropdown
                        showYearDropdown
                        selected={
                          dataForm.ngaysinha1
                            ? moment(dataForm.ngaysinha1, "DD/MM/YYYY").toDate()
                            : null
                        }
                        locale="vi"
                        onChange={(date) =>
                          handleDateChange(date, "ngaysinha1")
                        }
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Chọn ngày sinh"
                      />
                      {dataForm.ngaysinha1 && (
                        <div className="invalid-feedback">
                          Vui lòng nhập ngày sinh
                        </div>
                      )}
                    </td>
                    <td data-label="Nghề nghiệp">
                      <input
                        type="text"
                        className="form-control"
                        name="nghenghiepa1"
                        value={dataForm.nghenghiepa1}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Buôn bán"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập nghề nghiệp
                      </div>
                    </td>
                    <td data-label="Chỗ ở hiện tại">
                      <input
                        type="text"
                        className="form-control"
                        name="chooa1"
                        value={dataForm.chooa1}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: 63 đường 30/4 thị trấn Tân Hoà, ..."
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập chỗ ở hiện tại
                      </div>
                    </td>
                    <td data-label="Số điện thoại">
                      <input
                        type="tel"
                        minlength="10"
                        maxlength="10"
                        class="form-control"
                        name="sdta1"
                        value={dataForm.sdta1}
                        onChange={handleInputChange}
                        placeholder="Số điện thoại"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập Số điện thoại
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Mối quan hệ">
                      <input
                        name="a2"
                        value={dataForm.a2}
                        disabled
                        className="form-control"
                      />
                      <div className="invalid-feedback">
                        Vui lòng chọn mối quan hệ
                      </div>
                    </td>
                    <td data-label="Họ tên">
                      <input
                        type="text"
                        className="form-control"
                        name="hotena2"
                        value={dataForm.hotena2}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Nguyễn Văn Nam"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập họ tên
                      </div>
                    </td>
                    <td data-label="Ngày sinh">
                      <DatePicker
                        scrollableYearDropdown
                        showYearDropdown
                        selected={
                          dataForm.ngaysinha2
                            ? moment(dataForm.ngaysinha2, "DD/MM/YYYY").toDate()
                            : null
                        }
                        locale="vi"
                        onChange={(date) =>
                          handleDateChange(date, "ngaysinha2")
                        }
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Chọn ngày sinh"
                      />
                      {dataForm.ngaysinha2 && (
                        <div className="invalid-feedback">
                          Vui lòng nhập ngày sinh
                        </div>
                      )}
                    </td>
                    <td data-label="Nghề nghiệp">
                      <input
                        type="text"
                        className="form-control"
                        name="nghenghiepa2"
                        value={dataForm.nghenghiepa2}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Buôn bán"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập nghề nghiệp
                      </div>
                    </td>
                    <td data-label="Chỗ ở hiện tại">
                      <input
                        type="text"
                        className="form-control"
                        name="chooa2"
                        value={dataForm.chooa2}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: 63 đường 30/4 thị trấn Tân Hoà, ..."
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập chỗ ở hiện tại
                      </div>
                    </td>
                    <td data-label="Số điện thoại">
                      <input
                        type="tel"
                        minlength="10"
                        maxlength="10"
                        class="form-control"
                        name="sdta2"
                        value={dataForm.sdta2}
                        onChange={handleInputChange}
                        placeholder="Số điện thoại"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập Số điện thoại
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Mối quan hệ">
                      <input
                        name="c1"
                        value={dataForm.c1}
                        disabled
                        className="form-control"
                      />
                      <div className="invalid-feedback">
                        Vui lòng chọn mối quan hệ
                      </div>
                    </td>
                    <td data-label="Họ tên">
                      <input
                        type="text"
                        className="form-control"
                        name="hotenc1"
                        value={dataForm.hotenc1}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Nguyễn Văn Nam"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập họ tên
                      </div>
                    </td>
                    <td data-label="Ngày sinh">
                      <DatePicker
                        scrollableYearDropdown
                        showYearDropdown
                        selected={
                          dataForm.ngaysinhc1
                            ? moment(dataForm.ngaysinhc1, "DD/MM/YYYY").toDate()
                            : null
                        }
                        locale="vi"
                        onChange={(date) =>
                          handleDateChange(date, "ngaysinhc1")
                        }
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Chọn ngày sinh"
                      />
                      {dataForm.ngaysinhc1 && (
                        <div className="invalid-feedback">
                          Vui lòng nhập ngày sinh
                        </div>
                      )}
                    </td>
                    <td data-label="Nghề nghiệp">
                      <input
                        type="text"
                        className="form-control"
                        name="nghenghiepc1"
                        value={dataForm.nghenghiepc1}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Buôn bán"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập nghề nghiệp
                      </div>
                    </td>
                    <td data-label="Chỗ ở hiện tại">
                      <input
                        type="text"
                        className="form-control"
                        name="chooc1"
                        value={dataForm.chooc1}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: 63 đường 30/4 thị trấn Tân Hoà, ..."
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập chỗ ở hiện tại
                      </div>
                    </td>
                    <td data-label="Số điện thoại">
                      <input
                        type="tel"
                        minlength="10"
                        maxlength="10"
                        class="form-control"
                        name="sdtc1"
                        value={dataForm.sdtc1}
                        onChange={handleInputChange}
                        placeholder="Số điện thoại"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập Số điện thoại
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Mối quan hệ">
                      <input
                        name="c2"
                        value={dataForm.c2}
                        disabled
                        className="form-control"
                      />
                      <div className="invalid-feedback">
                        Vui lòng chọn mối quan hệ
                      </div>
                    </td>
                    <td data-label="Họ tên">
                      <input
                        type="text"
                        className="form-control"
                        name="hotenc2"
                        value={dataForm.hotenc2}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Nguyễn Văn Nam"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập họ tên
                      </div>
                    </td>
                    <td data-label="Ngày sinh">
                      <DatePicker
                        scrollableYearDropdown
                        showYearDropdown
                        selected={
                          dataForm.ngaysinhc2
                            ? moment(dataForm.ngaysinhc2, "DD/MM/YYYY").toDate()
                            : null
                        }
                        locale="vi"
                        onChange={(date) =>
                          handleDateChange(date, "ngaysinhc2")
                        }
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Chọn ngày sinh"
                      />
                      {dataForm.ngaysinhc2 && (
                        <div className="invalid-feedback">
                          Vui lòng nhập ngày sinh
                        </div>
                      )}
                    </td>
                    <td data-label="Nghề nghiệp">
                      <input
                        type="text"
                        className="form-control"
                        name="nghenghiepc2"
                        value={dataForm.nghenghiepc2}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Buôn bán"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập nghề nghiệp
                      </div>
                    </td>
                    <td data-label="Chỗ ở hiện tại">
                      <input
                        type="text"
                        className="form-control"
                        name="chooc2"
                        value={dataForm.chooc2}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: 63 đường 30/4 thị trấn Tân Hoà, ..."
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập chỗ ở hiện tại
                      </div>
                    </td>
                    <td data-label="Số điện thoại">
                      <input
                        type="tel"
                        minlength="10"
                        maxlength="10"
                        class="form-control"
                        name="sdtc2"
                        value={dataForm.sdtc2}
                        onChange={handleInputChange}
                        placeholder="Số điện thoại"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập Số điện thoại
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Mối quan hệ">
                      <input
                        name="e1"
                        value={dataForm.e1}
                        disabled
                        className="form-control"
                      />
                      <div className="invalid-feedback">
                        Vui lòng chọn mối quan hệ
                      </div>
                    </td>
                    <td data-label="Họ tên">
                      <input
                        type="text"
                        className="form-control"
                        name="hotene1"
                        value={dataForm.hotene1}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Nguyễn Văn Nam"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập họ tên
                      </div>
                    </td>
                    <td data-label="Ngày sinh">
                      <DatePicker
                        scrollableYearDropdown
                        showYearDropdown
                        selected={
                          dataForm.ngaysinhe1
                            ? moment(dataForm.ngaysinhe1, "DD/MM/YYYY").toDate()
                            : null
                        }
                        locale="vi"
                        onChange={(date) =>
                          handleDateChange(date, "ngaysinhe1")
                        }
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Chọn ngày sinh"
                      />
                      {dataForm.ngaysinhe1 && (
                        <div className="invalid-feedback">
                          Vui lòng nhập ngày sinh
                        </div>
                      )}
                    </td>
                    <td data-label="Nghề nghiệp">
                      <input
                        type="text"
                        className="form-control"
                        name="nghenghiepe1"
                        value={dataForm.nghenghiepe1}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Buôn bán"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập nghề nghiệp
                      </div>
                    </td>
                    <td data-label="Chỗ ở hiện tại">
                      <input
                        type="text"
                        className="form-control"
                        name="chooe1"
                        value={dataForm.chooe1}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: 63 đường 30/4 thị trấn Tân Hoà, ..."
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập chỗ ở hiện tại
                      </div>
                    </td>
                    <td data-label="Số điện thoại">
                      <input
                        type="tel"
                        minlength="10"
                        maxlength="10"
                        class="form-control"
                        name="sdte1"
                        value={dataForm.sdte1}
                        onChange={handleInputChange}
                        placeholder="Số điện thoại"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập Số điện thoại
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Mối quan hệ">
                      <input
                        name="e2"
                        value={dataForm.e2}
                        disabled
                        className="form-control"
                      />
                      <div className="invalid-feedback">
                        Vui lòng chọn mối quan hệ
                      </div>
                    </td>
                    <td data-label="Họ tên">
                      <input
                        type="text"
                        className="form-control"
                        name="hotene2"
                        value={dataForm.hotene2}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Nguyễn Văn Nam"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập họ tên
                      </div>
                    </td>
                    <td data-label="Ngày sinh">
                      <DatePicker
                        scrollableYearDropdown
                        showYearDropdown
                        selected={
                          dataForm.ngaysinhe2
                            ? moment(dataForm.ngaysinhe2, "DD/MM/YYYY").toDate()
                            : null
                        }
                        locale="vi"
                        onChange={(date) =>
                          handleDateChange(date, "ngaysinhe2")
                        }
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Chọn ngày sinh"
                      />
                      {dataForm.ngaysinhe2 && (
                        <div className="invalid-feedback">
                          Vui lòng nhập ngày sinh
                        </div>
                      )}
                    </td>
                    <td data-label="Nghề nghiệp">
                      <input
                        type="text"
                        className="form-control"
                        name="nghenghiepe2"
                        value={dataForm.nghenghiepe2}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Buôn bán"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập nghề nghiệp
                      </div>
                    </td>
                    <td data-label="Chỗ ở hiện tại">
                      <input
                        type="text"
                        className="form-control"
                        name="chooe2"
                        value={dataForm.chooe2}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: 63 đường 30/4 thị trấn Tân Hoà, ..."
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập chỗ ở hiện tại
                      </div>
                    </td>
                    <td data-label="Số điện thoại">
                      <input
                        type="tel"
                        minlength="10"
                        maxlength="10"
                        class="form-control"
                        name="sdte2"
                        value={dataForm.sdte2}
                        onChange={handleInputChange}
                        placeholder="Số điện thoại"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập Số điện thoại
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Mối quan hệ">
                      <input
                        name="acevo"
                        value={dataForm.acevo}
                        disabled
                        className="form-control"
                      />
                      <div className="invalid-feedback">
                        Vui lòng chọn mối quan hệ
                      </div>
                    </td>
                    <td data-label="Họ tên">
                      <input
                        type="text"
                        className="form-control"
                        name="hotenacevo"
                        value={dataForm.hotenacevo}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Nguyễn Văn Nam"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập họ tên
                      </div>
                    </td>
                    <td data-label="Ngày sinh">
                      <DatePicker
                        scrollableYearDropdown
                        showYearDropdown
                        selected={
                          dataForm.ngaysinhacevo
                            ? moment(
                                dataForm.ngaysinhacevo,
                                "DD/MM/YYYY"
                              ).toDate()
                            : null
                        }
                        onChange={(date) =>
                          handleDateChange(date, "ngaysinhacevo")
                        }
                        locale="vi"
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Chọn ngày sinh"
                      />
                      {dataForm.ngaysinhacevo && (
                        <div className="invalid-feedback">
                          Vui lòng nhập ngày sinh
                        </div>
                      )}
                    </td>
                    <td data-label="Nghề nghiệp">
                      <input
                        type="text"
                        className="form-control"
                        name="nghenghiepacevo"
                        value={dataForm.nghenghiepacevo}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Buôn bán"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập nghề nghiệp
                      </div>
                    </td>
                    <td data-label="Chỗ ở hiện tại">
                      <input
                        type="text"
                        className="form-control"
                        name="chooacevo"
                        value={dataForm.chooacevo}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: 63 đường 30/4 thị trấn Tân Hoà, ..."
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập chỗ ở hiện tại
                      </div>
                    </td>
                    <td data-label="Số điện thoại">
                      <input
                        type="tel"
                        minlength="10"
                        maxlength="10"
                        class="form-control"
                        name="sdtacevo"
                        value={dataForm.sdtacevo}
                        onChange={handleInputChange}
                        placeholder="Số điện thoại"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập Số điện thoại
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td data-label="Mối quan hệ">
                      <input
                        name="khac"
                        value={dataForm.khac}
                        disabled
                        className="form-control"
                      />
                      <div className="invalid-feedback">
                        Vui lòng chọn mối quan hệ
                      </div>
                    </td>
                    <td data-label="Họ tên">
                      <input
                        type="text"
                        className="form-control"
                        name="hotenkhac"
                        value={dataForm.hotenkhac}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Nguyễn Văn Nam"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập họ tên
                      </div>
                    </td>
                    <td data-label="Ngày sinh">
                      <DatePicker
                        scrollableYearDropdown
                        showYearDropdown
                        selected={
                          dataForm.ngaysinhkhac
                            ? moment(
                                dataForm.ngaysinhkhac,
                                "DD/MM/YYYY"
                              ).toDate()
                            : null
                        }
                        onChange={(date) =>
                          handleDateChange(date, "ngaysinhkhac")
                        }
                        locale="vi"
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        placeholderText="Chọn ngày sinh"
                      />
                      {dataForm.ngaysinhkhac && (
                        <div className="invalid-feedback">
                          Vui lòng nhập ngày sinh
                        </div>
                      )}
                    </td>
                    <td data-label="Nghề nghiệp">
                      <input
                        type="text"
                        className="form-control"
                        name="nghenghiepkhac"
                        value={dataForm.nghenghiepkhac}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: Buôn bán"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập nghề nghiệp
                      </div>
                    </td>
                    <td data-label="Chỗ ở hiện tại">
                      <input
                        type="text"
                        className="form-control"
                        name="chookhac"
                        value={dataForm.chookhac}
                        onChange={handleInputChange}
                        placeholder="Ví dụ: 63 đường 30/4 thị trấn Tân Hoà, ..."
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập chỗ ở hiện tại
                      </div>
                    </td>
                    <td data-label="Số điện thoại">
                      <input
                        type="tel"
                        minlength="10"
                        maxlength="10"
                        class="form-control"
                        name="sdtkhac"
                        value={dataForm.sdtkhac}
                        onChange={handleInputChange}
                        placeholder="Số điện thoại"
                      />
                      <div className="invalid-feedback">
                        Vui lòng nhập Số điện thoại
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row pb-4">
              <div class="col-md-9">
                <label for="" class="h5 lh-base">
                  Tôi xin cam đoan lời khai trên là đúng với sự thật và thực
                  hiện đúng các quy định tuyển dụng lao động.
                  <br />
                  Các thông tin cá nhân trên để Công ty liên lạc với tôi khi cần
                  thiết. Nếu có thay đổi thông tin cá nhân(kể cả số điện thoại),
                  tôi sẽ báo cáo lại với{" "}
                  <span onClick={handleShowNote}>Bộ phận Nhân sự</span> của Đơn
                  vị để cập nhật, thay đổi./.
                </label>
              </div>
              <div class="col-md-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="isSure"
                    name="isSure"
                  />
                  <div class="invalid-feedback">
                    Vui lòng xác nhận thông tin khai báo là chính xác.
                  </div>
                  <label class="form-check-label text-success" for="isSure">
                    Đồng ý
                  </label>
                </div>
              </div>
            </div>
            {showNote && (
              <div className="row mt-4 justify-content-around">
                <div class="row">
                  <label class="col-12 mb-4 pt-3 border-top border-2 fw-bold">
                    III. Ghi chú
                  </label>
                  <table className="table table-responsive table-bordered responsiveTable">
                    <thead className="text-center">
                      <tr>
                        <th>Ghi chú</th>
                        <th>Chứng minh thư</th>
                        <th>Ngày tháng vào</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td data-label="Ghi chú">
                          <textarea
                            rows="1"
                            className="form-control"
                            id="ghichu"
                            name="ghichu"
                            placeholder="Ghi chú"
                            value={dataForm.ghichu}
                            onChange={handleInputChange}
                          />
                        </td>
                        <td data-label="Chứng minh thư">
                          <textarea
                            rows="1"
                            className="form-control"
                            id="cmthu"
                            name="cmthu"
                            placeholder="Chứng minh thư"
                            value={dataForm.cmthu}
                            onChange={handleInputChange}
                          />
                        </td>
                        <td data-label="Ngày vào làm">
                          <DatePicker
                            scrollableYearDropdown
                            showYearDropdown
                            selected={
                              dataForm.ngayvaolam
                                ? moment(
                                    dataForm.ngayvaolam,
                                    "DD/MM/YYYY"
                                  ).toDate()
                                : null
                            }
                            locale="vi"
                            onChange={(date) =>
                              handleDateChange(date, "ngayvaolam")
                            }
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            placeholderText="Chọn ngày vào làm"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-12">
                <button
                  onClick={handleSubmit}
                  className="btn btn-primary w-100 mb-4 font-size-1-2"
                >
                  Cập nhật
                </button>
              </div>
            </div>
          </>
        )}
        {error && <p className="error">{error}</p>}
      </form>
    </div>
  );
}

export default ChiTiet;

                                                                                                                                                                                                                                                                                                                                                                          

